$input-image-width: 292px;

.number-question {
  .survey-input {
    width: $input-image-width;
    margin-right: 50px;
  }
}

.number-question__image {
  width: $input-image-width;
}
