.multiple-choice-question {
    .subquestion-wrapper {
        display: flex;
        flex-wrap: wrap;

        .choice-question-input-container {
            margin-right: 15px;
            width: 280px;
        }
    }

    .subquestion-wrapper:focus {
        outline: 0;
    }
}


