@import "./question.scss";
@import "./_colors.scss";
@import "./themes/default-theme.scss";

// Disables blue outline for any focused div with a negative tabindex
div[tabindex="-1"]:focus {
  outline: 0;
}

.survey {
  // padding-top: 80px;

  @media (max-width: 767px) {
    margin-top: 46px;
  }
}

.survey__end-of-survey-text {
  font-weight: bold;
}

.survey-footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  border-top: 1px solid lightgray;
  z-index: 200;

  .flex-container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
    height: 100%;
    padding: 10px 0px;

    @media (max-width: 767px) {
      padding: 10px;
    }

    .left-container {
      flex: 1 0 auto;
      width: 100%;
    }

    .button-container {
      flex: 0 0 auto;
      display: flex;

      button {
        margin-left: 10px;
        padding: 10px;
        background-color: rgba(
          map-get($map: $default-theme, $key: primaryColor),
          0.08
        );
        border: 1px solid map-get($map: $default-theme, $key: primaryColor);
        border-radius: 4px;
        min-width: 70px;
      }

      button:hover {
        background-color: rgba(
          map-get($map: $default-theme, $key: primaryColor),
          0.3
        );
      }
    }
  }
}
