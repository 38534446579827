.will-you-live-to-see-it__svg {
    overflow: visible !important;
}

.will-you-live-to-see-it-wrapper {
    min-height: 100vh;
    padding-top: 66px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:focus {
        outline: 0;
    }

    h3 {
        font-size: 31px;
        font-weight: bold;
        line-height: 35px;
        color: #515151; // TODO: Make this the body color
    }

    p {
        font-size: 20px;
        font-weight: 25px;
        margin-bottom: 20px;
    }

    .button-prompt--embedded {
        margin-left: 0px;
        color: gray;
    }
}

.will-you-live-to-see-it {
    width: 100%;
    height: 465px;
}

.will-you-live-to-see-it__bar-text {
    font-weight: bold;
}

.will-you-live-to-see-it__bar-lower-age-text {
    font-weight: bold;
}

.will-you-live-to-see-it__circle {
    fill: rgb(100, 208, 250);
}

.will-you-live-to-see-it__percentage {
    color: rgb(100, 208, 250);
    font-size: 40px;
}

.will-you-live-to-see-it__text-container {
    display: inline-block;
}

.will-you-live-to-see-it__percentage-text-container {
    margin-bottom: 10px;
    display: inline-block;
}

.will-you-live-to-see-it__percentage-text {
    color: rgb(100, 208, 250);
    font-size: 21px;
}

.will-you-live-to-see-it__text {
    font-size: 21px;
    color: #858585;
    margin-bottom: 10px;
    line-height: 25px;
}

.will-you-live-to-see-it__age {
    font-size: 21px;
    color: rgba(133, 133, 133, 0.5);
}

.will-you-live-to-see-it__line {
    stroke: rgb(100, 208, 250);
    stroke-width: 2px;
    fill: transparent;
}

.will-you-live-to-see-it__slider-line {
    stroke: rgba(133, 133, 133, 0.5);
    stroke-width: 1px;
    stroke-dasharray: 5, 5;
}

.will-you-live-see-it__drag-me-text--hidden {
    visibility: hidden;
}

.result--embedded {
    min-height: auto;
    margin-bottom: 50px;
}
