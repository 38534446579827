.flexible-row-array-question {
    th {
        border-bottom: none !important;
        text-align: center;
        padding: 10px !important;
    }

    td {
        border-top: none !important;
        vertical-align: middle !important;
        padding: 10px !important;
    }

    .radio {
        display: inline;
    }

    .radio-answer-option__label {
        font-size: 14px;
        font-weight: 700;
    }
}

.flexible-row-array-question__sub-question-text {
    font-size: 20px;
}

.flexible-row-array-question__answer-options-container {
    padding-left: 10px;
}

.flexible-row-array-question__sub-questions-container {
    padding-left: 10px;
    margin-bottom: 10px;
}

.flexible-row-array-question__sub-questions-container--last-sub-question {
    margin-bottom: 0px;
}
