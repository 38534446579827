.health-age-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:focus {
        outline: 0;
    }

    h3 {
        font-size: 31px;
        font-weight: bold;
        line-height: 35px;
        color: #515151; // TODO: Make this the body color
    }

    p {
        font-size: 20px;
        font-weight: 25px;
        margin-bottom: 20px;
    }

    .compare-me-to-div {
        display: flex;
        font-size: 20px;
        align-items: center;
        margin-bottom: 15px;

        .radio-answer-question {
            margin-left: 20px;
            margin-bottom: -13px;
        }
    }
}

.health-age-result--embedded {
    height: auto;
    margin-bottom: 50px;
}

.health-age {
    height: 180px;
    width: 100%;
}

.health-age__grey-bar-rect {
    fill: #e4e4e4;
}

.health-age__blue-bar-rect {
    fill: #64d0fa;
}

.health-age__health-age-number-text {
    fill: white;
}

.health-age__health-age-years-text {
    fill: white;
}

.health-age__current-age-line {
    stroke: rgb(152, 152, 152);
    stroke-width: 1px;
    stroke-dasharray: 5, 5;
}

.health-age__current-age-circle {
    fill: rgb(197, 197, 197);
}

.health-age__current-age-text {
    fill: rgb(152, 152, 152);
}
