@import "./_common";

.multiple-number-question {
  .sub-question-text {
    font-size: 20px;
  }
}

.multiple-number-question__sub-questions-container--image-sub-questions {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.multiple-number-question__error-text {
  @extend %error-text;
  text-align: center;
}
