.connected-question {
  .survey-input__form-group {
    display: inline-block;
    margin-right: 10px;
  }

  .survey-input {
    max-width: 100px;
  }

  .sub-question-number-input {
    
    .survey-input__label {
      display: none;
    }

    .survey-input__input-underline {
      margin-top: 0px;
    }
  }
}

.connected-question__sub-questions-container {
  display: inline-block;
}

.connected-question__or-text {
  display: inline-block;
  padding-top: 33px;
  padding-right: 30px;
  padding-left: 30px;
  color: #9b9b9b;
  font-size: 18px;
  font-weight: 300;
}

.connected-questions__questions-and-or-container {
  display: flex;
  // flex-wrap: wrap;
}
