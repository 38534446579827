@mixin survey-theme($theme) {
  .group {
    .group-header {
      border-bottom: 1px solid map-get($map: $theme, $key: primaryColor);

      .group-name {
        color: map-get($map: $theme, $key: primaryColor);
      }
    }
  }

  .choice-question-input-container {
    border-color: map-get($map: $theme, $key: primaryColor);
    background-color: rgba(map-get($map: $theme, $key: primaryColor), 0.08);

    .keypress-label {
      color: map-get($map: $theme, $key: primaryColor);
      border-color: map-get($map: $theme, $key: primaryColor);
    }
  }

  .choice-question-input-container:hover {
    background-color: rgba(map-get($map: $theme, $key: primaryColor), 0.3);
    border-color: map-get($map: $theme, $key: primaryColorDark);
  }

  .choice-question-input-container--selected {
    border-color: map-get($map: $theme, $key: primaryColor) !important;
    background-color: rgba(
      map-get($map: $theme, $key: primaryColor),
      0.3
    ) !important;
  }

  .survey-input {
    .survey-input__input-underline:after {
      border-bottom: 2px solid map-get($map: $theme, $key: primaryColor);
    }
  }

  .appearing-button {
    background-color: map-get($map: $theme, $key: primaryColor);
  }

  .choice-question-input-container__check-icon {
    color: map-get($map: $theme, $key: primaryColorDark);
  }

  .survey-progress-bar {
    .survey-progress-bar__group-name {
      color: map-get($map: $theme, $key: primaryColor);
    }

    .survey-progress-bar__group-progress-bar {
      background-color: rgba(map-get($map: $theme, $key: primaryColor), 0.25);
    }

    .survey-progress-bar__group-progress-bar-progress {
      background-color: map-get($map: $theme, $key: primaryColor);
    }
  }

  .survey-footer {
    .flex-container {
      .button-container {
        button {
          background-color: rgba(
            map-get($map: $theme, $key: primaryColor),
            0.08
          );
          border: 1px solid map-get($map: $theme, $key: primaryColor);
        }

        button:hover {
          background-color: rgba(
            map-get($map: $theme, $key: primaryColor),
            0.3
          );
        }
      }
    }
  }

  .question-helper-container {
    color: map-get($map: $theme, $key: primaryColor);
  }
}

@function make-theme-map($primary-color) {
  @return (
    primaryColor: $primary-color,
    primaryColorDark: darken($primary-color, 21)
  );
}
