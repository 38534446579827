@import "./themes/default-theme.scss";

.choice-question-input-container {
  display: flex;
  width: 320px;
  min-height: 40px;
  margin-bottom: 13px;
  padding: 6px 15px 5px 10px;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: map-get($map: $default-theme, $key: primaryColor);
  border-radius: 4px;
  background-color: rgba(
    map-get($map: $default-theme, $key: primaryColor),
    0.08
  );
  // opacity:0.08;
  input {
    width: 0px;
    height: 0px;
    opacity: 0;
  }

  .keypress-label {
    border-style: solid;
    border-width: 1px;
    color: map-get($map: $default-theme, $key: primaryColor);
    border-color: map-get($map: $default-theme, $key: primaryColor);
    padding: 0px 3px;
    text-align: center;
    border-radius: 4px;
    margin-right: 20px;
    width: 25px;
    height: 25px;
    flex: 0 0 auto;
  }
}

.choice-question-input-container--selected {
  border-width: 2px;
  border-color: map-get($map: $default-theme, $key: primaryColor) !important;
  background-color: rgba(
    map-get($map: $default-theme, $key: primaryColor),
    0.3
  ) !important;
}

.choice-question-input-container--images {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 160px;
  width: 130px;
  margin-right: 10px;
}

.choice-question-input-container--nocheck {
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: auto;
  padding: 6px 15px 5px 15px;
  margin-right: 10px;

  .choice-question-input-container__label {
    text-align: center;
  }
}

.choice-question-input-container__image {
  width: auto;
  height: 70%;
  margin-bottom: 10px;
}

.choice-question-input-container--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.choice-question-input-container:hover {
  border-color: map-get($map: $default-theme, $key: primaryColorDark);
  background-color: rgba(
    map-get($map: $default-theme, $key: primaryColor),
    0.3
  );
  // opacity:0.3;
}

.choice-question-input-container:focus-within {
  // border-color: #2faedf;
  // background-color: rgba(100, 208, 250, 0.3);
}

.choice-question-input-container__label {
  color: rgb(81, 81, 81);
  font-size: 20px;
  line-height: 20px;
  text-align: left;
  font-weight: 350;
}

.choice-question-input-container__check-icon-container {
  flex-grow: 1;
  text-align: right;
}

.choice-question-input-container__check-icon {
  color: map-get($map: $default-theme, $key: primaryColorDark);
  visibility: hidden;
  vertical-align: middle;
}

.choice-question-input-container__check-icon--selected {
  visibility: visible;
}
