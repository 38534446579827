@import "./themes/default-theme.scss";

.survey-progress-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: -20px;

  .total,
  .groups {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1 auto;
  }

  .total {
    display: none;
  }

  @media (max-width: 767px) {
    .groups {
      display: none;
    }
    .total {
      display: flex;
    }
  }
}

.survey-progress-bar__group-name {
  font-size: 18px;
  font-weight: bold;
  color: map-get($map: $default-theme, $key: primaryColor);
  margin-bottom: 10px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.survey-progress-bar__group-container {
  margin-bottom: 20px;
  margin-right: 35px;

  display: flex;
  flex-direction: column;

  flex: 1 1 auto;
}

.survey-progress-bar__group-progress-bar {
  height: 5px;
  background-color: rgba(
    map-get($map: $default-theme, $key: primaryColor),
    0.25
  );
  border-radius: 4px;
}

.survey-progress-bar__group-progress-bar-progress {
  height: 5px;
  background-color: map-get($map: $default-theme, $key: primaryColor);
  border-radius: 4px;

  transition-property: width;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
}
