.icon-graph {
    flex-basis: 100%;
    width: 100%;
    background-color: transparent;
    line-height: 0px;
    position: relative;
    padding: 0px;
}

.icon-graph__icon {
    stroke: rgba(255, 255, 255, 0.29);
    fill: rgba(255, 255, 255, 0.29);
}

.icon-graph__icon--selected {
    stroke: #ffffff;
    fill: #ffffff;
}
