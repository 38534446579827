.question {
    margin-bottom: 168px;
    line-height: 25px;

    /* Styles for auto-scroll */
    // min-height: 100vh; //50 pixels for
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.question--embedded {
    margin-bottom: 50px;
}

.question-wrapper {
    opacity: 0.3;

    &.current {
        opacity: 1;
    }
}

.question-wrapper--embedded {
    opacity: 1;
}

/* This is to fix the issue where on large screens the first question isn't the first one selected. This isn't a perfect solution, but it will work for now */
.question--large-screen-focus-fix {
    height: 39vh;
}

.question__help-text {
    font-size: 20px;
    margin-bottom: 25px;
}

// Text above first question in a group
.group-description-text {
    font-size: 0.7em;
    line-height: 1.3em;
    padding-bottom: 60px;
}
