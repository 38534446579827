@import "./_colors";
@import "./_common";
@import "./themes/default-theme.scss";

.survey-input {
  .survey-input__input {
    font-size: 28px;
    margin-bottom: 5px;
  }

  .survey-input__input-underline:after {
    border-bottom: 2px solid map-get($map: $default-theme, $key: primaryColor);
  }

  .survey-input__input-underline:hover:not(.survey-input__input--disabled):not(.survey-input__input--focused):not(.survey-input__input--error):before {
    border-bottom: 2px solid #9b9b9b;
  }

  .survey-input__input-underline:before {
    //border-bottom: 1px solid $input-color;
  }

  .survey-input__error-text {
    @extend %error-text;
  }

  .survey-input__suffix-text {
    margin-bottom: 5px;
    color: #9b9b9b;
    font-size: 16px;
  }
}
