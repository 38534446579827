@import "./themes/default-theme.scss";

.appearing-button {
  padding: 10px 20px;
  background-color: map-get($map: $default-theme, $key: primaryColor);
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 4px;
  flex-grow: 0;
}

.buttom-prompt {
  color: lightgray;
  margin-left: 15px;
}

.appearing-button-wrapper {
  opacity: 0;
  transition: all 0.35s ease-out;
  display: flex;
  align-items: center;
}

.appearing-button-wrapper.visible {
  // display:block;
  opacity: 1;
  transform: translateY(0);
}

.appearing-button-wrapper.hidden {
  opacity: 0;
  transform: translateY(20px);
}

.appearing-button-wrapper.disabled {
  opacity: 0.3;
  &.hidden {
    opacity: 0;
  }
}
