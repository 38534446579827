.question-text-container {
    display: flex;
}

.question-help {
    font-size: 2em;
    line-height: 1em;
}

.question-text {
    font-size: 31px;
    color: #515151;
    margin-bottom: 25px;
    line-height: 35px;
}
.question-text--with-help-text {
    margin-bottom: 0px;
}
