@import "./_colors.scss";
@import "./themes/default-theme.scss";

.group {
  .group-name {
    font-size: 25px;
    line-height: 1em;
    color: map-get($map: $default-theme, $key: primaryColor);
    font-family: "Bariol";
    padding: 10px 0px;
  }

  .group-description {
    font-size: 30px;
  }

  .group-header {
    border-bottom: 1px solid map-get($map: $default-theme, $key: primaryColor);
    margin-bottom: 30px;
    background-color: white;
    position: sticky;
    position: -webkit-sticky;
    top: 80px; // Should match the height of the navbar
    left: 0px;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0px 20px;
    z-index: 100;

    // No fixed navbar for mobile screens
    @media (max-width: 767px) {
      top: 0px;
    }

    .progress-indicator-bg {
      height: 5px;
      background-color: rgb(250, 250, 250);
      display: none;

      @media (max-width: 767px) {
        display: block;
      }

      margin-left: -20px;
      margin-right: -20px;

      .progress-indicator {
        height: 5px;
        background-color: $default-theme-color;

        transition-property: width;
        transition-duration: 1s;
        transition-timing-function: cubic-bezier(1, 0, 0, 1);
      }
    }
  }

  .group-header > * {
    -webkit-transform: translateZ(0);
  }
}
