.image-sub-number-question-input {
  .survey-input__input {
    width: 100px;
    text-align:center;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-right: 20px;
}

.image-sub-number-question-input__image {
  width: auto;
  height: 150px;
}

.image-sub-number-question-input__text {
  text-align: center;
  font-size: 25px;
  margin-bottom: 10px;
}
