.life-years-lost-result {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .button-prompt--embedded {
        margin-left: 0px;
        color: gray;
    }

    &:focus {
        outline: 0;
    }

    h3 {
        font-size: 31px;
        font-weight: bold;
        line-height: 35px;
        color: #515151; // TODO: Make this the body color
    }

    p {
        font-size: 20px;
        font-weight: 25px;
        margin-bottom: 20px;
    }

    .risk-factors-container {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;

        .risk-factor-container {
            flex: 1 1 auto;
            text-align: center;
            padding: 20px;
            padding-top: 40px;
            overflow: hidden;
            border-radius: 4px;
            margin-bottom: 20px;

            .risk-factor-image-container {
                max-width: 180px;
                max-height: 140px;
                margin-left: auto;
                margin-right: auto;

                .risk-factor-image {
                    max-height: 140px;
                }
            }

            .risk-factor-title {
                font-size: 24px;
                font-weight: bold;
                margin-top: 20px;
            }

            .risk-factor-value {
                font-size: 46px;
                color: #64d0fa;
                line-height: 1em;
                margin-top: 10px;
                font-weight: bold;
            }

            .risk-factor-years-label {
                color: #515151;
                font-size: 18px;
            }
        }

        .risk-factor-container.largest-risk {
            background-color: #d7f3fd;
            position: relative;

            .largest-risk-header {
                background-color: #64d0fa;
                color: white;
                font-size: 21px;
                font-weight: bold;
                height: 30px;
                position: absolute;
                left: 0px;
                top: 0px;
                right: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .calculate-life-years-lost {
        margin-bottom: 10px;
    }
}

.result--embedded {
    min-height: auto;
    margin-bottom: 50px;
}
