.life-expectancy-result {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:focus {
        outline: 0;
    }

    h3 {
        font-size: 31px;
        font-weight: bold;
        line-height: 35px;
        color: #515151; // TODO: Make this the body color
    }

    p {
        font-size: 20px;
        font-weight: 25px;
        margin-bottom: 20px;
    }

    .life-expectancy-value {
        font-size: 80px;
        color: #64d0fa;
        line-height: 60px;
        margin-top: 20px;
        font-weight: bold;
    }

    .years-label {
        font-size: 22px;
        color: #515151;
    }

    .button-prompt--embedded {
        margin-left: 0px;
        color: gray;
    }
}

.result--embedded {
    min-height: auto;
    margin-bottom: 50px;
}
