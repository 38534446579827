.cvd-port-risk-result {

    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
        font-size: 31px;
        font-weight:bold;
        line-height: 35px;
        color: #515151; // TODO: Make this the body color
    }

    p {
        font-size: 20px;
        font-weight: 25px;
        margin-bottom:20px;
    }

    .life-expectancy-value {
        font-size: 80px;
        color:#E93C3C;
        line-height: 60px;
        margin-top:20px;
        font-weight: bold;
    }

    .years-label {
        font-size:22px;
        color: #515151;
    }
}

.cvd-port-risk-result:focus {
    outline: 0;
}