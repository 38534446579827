$survival-color: #9c9c9c;
$risk-color: #e93c3c;

.cvdport-icon-graph {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
        font-size: 31px;
        font-weight: bold;
        line-height: 35px;
        color: #515151; // TODO: Make this the body color
    }

    p {
        font-size: 20px;
        font-weight: 25px;
        margin-bottom: 20px;
    }

    .cvdport-icon-graph__value {
        font-size: 80px;
        color: #64d0fa;
        line-height: 60px;
        margin-top: 20px;
        font-weight: bold;
    }

    .cvdport-icon-graph__value-label {
        font-size: 22px;
        color: #515151;
    }

    strong {
        font-size: 24px;
    }

    .mortality {
        color: $survival-color;
    }

    .survival {
        color: $risk-color;
    }
}

.cvdport-icon-graph:focus {
    outline: 0;
}

.cvdport-icon-graph__icon-graph-container {
    display: flex;
    margin-bottom: 20px;
}

.icon-graph {
    height: 400px;
    max-width: 400px;
}

.cvdport-icon-graph__icon-graph {
    flex: 1 1 auto;

    .icon-graph__icon {
        fill: $survival-color;
    }

    .icon-graph__icon--selected {
        fill: $risk-color;
    }
}
